<template>
  <div>
    <Draggable
        v-model="data"
        class="row no-gutters mx-n1 mb-3"
        tag="div"
        v-bind="DragOptions"
        @start="DragStart"
        @end="DragEnd"
    >
      <CCol v-for="(Data, Index) in data" :key="Index" xl="3" md="4" sm="6" class="px-1 mb-2">
        <div class="position-relative CarouselItem">
          <CImg :src="Data.URL" block fluid placeholderColor="'#cccccc'" />
          <div class="CarouselTools">
            <CButton color="info" size="sm" class="mx-1" @click="OpenMediaStore(false, Index)">
              <CIcon name="cil-image-plus" />
            </CButton>
            <CButton color="secondary" size="sm" class="mx-1" @click="SetMeta(Index)">
              <CIcon name="cil-pencil" />
            </CButton>
            <CButton color="danger" size="sm" class="mx-1" @click="RemoveImage(Index)">
              <CIcon name="cil-trash" />
            </CButton>
          </div>
        </div>
      </CCol>
      <CCol xl="3" md="4" sm="6" class="px-1 mb-2">
        <div class="bg-light h-100 d-flex justify-content-center align-content-center CarouselItem">
          <CIcon name="cil-image-plus" class="c-icon-custom-size AddImages" />
          <div class="CarouselTools">
            <CButton color="success" size="sm" class="mx-1" @click="OpenMediaStore()">
              <CIcon name="cil-image-plus" /> {{ $t('Global.Add') }}
            </CButton>
          </div>
        </div>
      </CCol>
    </Draggable>
    <div class="text-right">
      <CButton color="success" type="button" @click="Action('save')">
        {{ $t('Global.Update')  }}
      </CButton>
    </div>
    <MediaStore :Config="MediaStoreConfig" @OK="SetImages" />
    <CModal
        :show.sync="NowChooseImageModal"
        :centered="true"
        :title="$t('System/Themes.ImageMeta')"
        color="dark"
    >
      <CForm>
        <CInput v-model="NowChooseImageMeta.URL" size="sm" :label="$t('Global.URL')" :placeholder="$t('Global.URL')" type="text" />
        <CInput v-model="NowChooseImageMeta.Alt" size="sm" :label="$t('Global.Alt')" :placeholder="$t('Global.Alt')" type="text" />
        <CInput v-model="NowChooseImageMeta.Link" size="sm" :label="$t('Global.Link')" :description="$t('Message.System/LinkInfo')" :placeholder="$t('Global.Link')" type="text" />
        <CInput v-model="NowChooseImageMeta.Title" size="sm" :label="$t('Global.Title')" :placeholder="$t('Global.Title')" type="text" />
        <CTextarea v-model="NowChooseImageMeta.Content" :label="$t('Global.Content')" :placeholder="$t('Global.Content')" size="sm" rows="5" />
      </CForm>
      <template #footer>
        <CButton @click="NowChooseImageModal = false" color="success">{{ $t('Global.Confirm') }}</CButton>
      </template>
    </CModal>
  </div>
</template>

<script>
export default {
  props: {
    data: Array,
    title: String
  },
  name: 'Carousel',
  components: {
    Draggable: () => import('vuedraggable'),
    MediaStore: () => import('@/components/public/Media')
  },
  computed: {
    DragOptions () {
      return {
        animation: 200,
        group: 'description',
        disabled: false,
        ghostClass: 'ghost'
      }
    }
  },
  data() {
    return {
      ChooseImageIndex: false,
      Drag: false,
      MediaStoreConfig: {
        Display: false,
        ChooseImageList: [],
        Multiple: false
      },
      NowChooseImageModal: false,
      NowChooseImageMeta: {
          LanguageData: {}
      }
    }
  },
  mounted() {
  },
  methods: {
    OpenMediaStore(multiple = true, index = false) {
      this.MediaStoreConfig.ChooseImageList = []
      this.MediaStoreConfig.Display = !this.MediaStoreConfig.Display
      this.MediaStoreConfig.Multiple = multiple
      if (index !== false) {
        this.ChooseImageIndex = index
      }
    },
    RemoveImage(index) {
      this.data.splice(index, 1)
      this.$emit('update:data', this.data)
    },
    SetImages () {
      // Multiple = true 批量新增；Multiple = false 個別編輯
      const storageInstance = this.$store.state.userProjectApp.storage()
      if (this.MediaStoreConfig.Multiple === true && Array.isArray(this.MediaStoreConfig.ChooseImageList)) {
        // 新增
        this.MediaStoreConfig.ChooseImageList.forEach(imgPath => {
          const PushData = {
            Alt: '',
            Content: '',
            Link: '',
            Title: '',
            Type: 'images'
          }
          if (this.$store.state.user.permission.StaticDomain) {
            this.data.push({
              ...PushData,
              URL: this.$store.state.user.permission.StaticDomain + imgPath
            })
          } else {
            storageInstance.ref().child(imgPath).getDownloadURL().then((URL) => {
              this.data.push({
                ...PushData,
                URL
              })
            })
          }
        })
      } else {
        // 編輯
        if (this.$store.state.user.permission.StaticDomain) {
          this.$set(this.data, parseInt(this.ChooseImageIndex), {
            ...this.data[this.ChooseImageIndex],
            URL: this.$store.state.user.permission.StaticDomain + this.MediaStoreConfig.ChooseImageList,
            Type: 'images'
          })
        } else {
          storageInstance.ref().child(this.MediaStoreConfig.ChooseImageList).getDownloadURL().then((URL) => {
            this.$set(this.data, parseInt(this.ChooseImageIndex), {
              ...this.data[this.ChooseImageIndex],
              URL,
              Type: 'images'
            })
          })
        }
      }
      this.MediaStoreConfig.ChooseImageList = []
    },
    DragStart() {
      this.Drag = true
    },
    DragEnd() {
      this.Drag = false
      this.$emit('update:data', this.data)
    },
    SetMeta(index) {
      this.NowChooseImageModal = true
      this.$set(this.$data, 'NowChooseImageMeta', this.data[index] )
    },
    Action(type) {
      this.$emit(type, this.$data)
    }
  }
}
</script>
